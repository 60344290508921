<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3 px-3">
      <div class="text-h6">Calculation Formulas</div>

      <v-dialog v-model="dialog" persistent max-width="500px">
        <template #activator="{ on }">
          <v-btn
            small
            depressed
            color="success"
            :disabled="disabledSave"
            v-on="on"
            >Save</v-btn
          >
        </template>
        <v-card class="pa-8">
          <div class="text-center">
            <div class="font-weight-medium">
              This will affect all reservations starting from
            </div>
            <div class="d-inline-flex ma-6">
              <v-text-field
                v-model="commissionRecalcFrom"
                :max="$moment().utc().format('YYYY-MM-DD')"
                outlined
                dense
                hide-details
                type="date"
                @focus="e => e.target.showPicker()"
              />
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn color="secondary" text @click="dialog = false">Cancel</v-btn>
            <v-btn
              color="success"
              class="font-weight-bold"
              text
              @click="updateListing"
              >Save</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3 pb-3">
      <v-row v-if="settings" no-gutters>
        <v-col cols="12">
          <h5 class="mb-4">PMC</h5>
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-text-field
                v-model.number="managementCommissionAbove"
                :label="$t('Commission Percent')"
                outlined
                type="number"
                dense
                :clearable="isHotel && !isDisabledManagementCommissionAbove"
                :hint="$t(managementCommissionAboveHint)"
                persistent-hint
                :min="0"
                :max="1"
                step="0.01"
                :rules="[
                  v =>
                    (v >= 0 && v <= 1) || $t('Value must be between 0 and 1'),
                ]"
                :disabled="isDisabledManagementCommissionAbove"
                @input="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Management commission percent</b> - The percentage
                        that the company takes from each reservation. This value
                        will be automatically applied in the management
                        commission formula.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-select
                v-model="managementCommissionFormula"
                outlined
                prepend-inner-icon="mdi-calculator"
                label="Management Commission Formula"
                dense
                clearable
                :hint="
                  zone.management_commission_formula ||
                  settings.management_commission_formula
                    ? `Using system default (${
                        zone.management_commission_formula ||
                        settings.management_commission_formula
                      })`
                    : null
                "
                :persistent-hint="!managementCommissionFormula"
                :items="MANAGEMENT_COMMISSION_FORMULAS"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Management income formula</b> - the amount of money
                        the company is making from the reservation
                      </v-col>
                      <v-col cols="12">
                        Variables: <br />
                        <ul>
                          <li>
                            <b>Fare Accommodation:</b> The nightly rate applied
                            to the reservation.
                          </li>
                          <li>
                            <b>Cleaning Fee:</b> The cleaning charge collected
                            from the guest.
                          </li>

                          <li>
                            <b>Total taxes:</b> the total taxes reported on the
                            reservation.
                          </li>
                          <li>
                            <b>Commission Percent:</b> the percent defined in
                            the commission percent
                          </li>
                          <li>
                            <b>Host Payout / Total Payout:</b> The total amount
                            the host received from the booking channel.
                          </li>
                          <li>
                            <b>Net Income:</b> Defined by a custom formula that
                            factors in the above variables.
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-select
                v-model="netIncomeFormula"
                outlined
                prepend-inner-icon="mdi-calculator"
                label="Net Income Formula"
                dense
                clearable
                :hint="
                  zone.net_income_formula || settings.net_income_formula
                    ? `Using system default (${
                        zone.net_income_formula || settings.net_income_formula
                      })`
                    : null
                "
                :persistent-hint="!netIncomeFormula"
                :items="NET_INCOME_FORMULAS"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Net income formula</b> - A formula used to calculate
                        and set the net income variable for each reservation. It
                        represents the actual earnings from the reservation
                        after deducting applicable fees and expenses
                      </v-col>
                      <v-col cols="12">
                        Variables: <br />
                        <ul>
                          <li>
                            <b>Fare Accommodation:</b> The nightly rate applied
                            to the reservation.
                          </li>
                          <li>
                            <b>Cleaning Fee:</b> The cleaning charge collected
                            from the guest.
                          </li>
                          <li>
                            <b>Reported Channel Fee:</b> The channel fee
                            recorded after the reservation is completed. In
                            platforms like Airbnb, this would typically be 0, as
                            it is deducted in advance.
                          </li>
                          <li>
                            <b>Host Channel Fee:</b> The fee charged by the
                            booking platform (applicable for Airbnb and other
                            channels).
                          </li>
                          <li>
                            <b>Processing Fee:</b> A fee set for reservations
                            where you act as the Merchant of Record (MOR),
                            covering transaction processing costs.
                          </li>
                          <li>
                            <b>Host Payout / Total Payout:</b> The total amount
                            the host received from the booking channel.
                          </li>
                          <li>
                            <b>Client Price:</b> The amount the guest paid on
                            the OTA platform, inclusive of channel fees (e.g.,
                            Airbnb, Booking.com).
                          </li>
                          <li>
                            <b>Net Income:</b> Defined by a custom formula that
                            factors in the above variables.
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12">
          <h5 class="mb-4">Owner</h5>
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-select
                v-model="ownerRevenueFormula"
                outlined
                label="Owner/Client Profit Formula"
                dense
                prepend-inner-icon="mdi-calculator"
                clearable
                :hint="
                  zone.owner_revenue_formula || settings.owner_revenue_formula
                    ? `Using system default (${
                        zone.owner_revenue_formula ||
                        settings.owner_revenue_formula
                      })`
                    : null
                "
                :persistent-hint="!ownerRevenueFormula"
                :items="OWNER_REVENUE_FORMULAS"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Owner profit formula</b> - A formula that calculates
                        the owner's profit for each reservation. This value will
                        be used to set the client_profit field in the
                        reservation, reflecting the owner's earnings after all
                        relevant fees and expenses are deducted
                      </v-col>
                      <v-col cols="12">
                        Variables: <br />
                        <ul>
                          <li>
                            <b>Fare Accommodation:</b> The nightly rate applied
                            to the reservation.
                          </li>
                          <li>
                            <b>Management commission:</b> The total fee charged
                            by the pmc on the reservaition
                          </li>
                          <li>
                            <b>Host Channel Fee:</b> The fee charged by the
                            booking platform (applicable for Airbnb and other
                            channels).
                          </li>
                          <li>
                            <b>Processing Fee:</b> A fee set for reservations
                            where you act as the Merchant of Record (MOR),
                            covering transaction processing costs.
                          </li>
                          <li>
                            <b>Net Income:</b> Defined by a custom formula that
                            factors in the above variables.
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-select
                v-model="ownerIncomeFormula"
                outlined
                label="Owner Income Formula"
                dense
                prepend-inner-icon="mdi-calculator"
                clearable
                :hint="
                  settings.owners_income_formula
                    ? `Using system default (${settings.owners_income_formula})`
                    : null
                "
                :persistent-hint="!ownerIncomeFormula"
                :items="OWNERS_INCOME_FORMULAS"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Owner income setting</b> - This setting determines
                        the owner's income for each reservation, as displayed on
                        the owner's dashboard calendar view. It also feeds into
                        the profit and loss (P&L) calculations in the income
                        section, providing a clear breakdown of the owner's
                        earnings.
                      </v-col>
                      <v-col cols="12">
                        Variables: <br />
                        <ul>
                          <li>
                            <b>Fare Accommodation:</b> The nightly rate applied
                            to the reservation.
                          </li>
                          <li>
                            <b>Net Income:</b> Defined by a net income formula
                            that factors in the above variables.
                          </li>
                          <li>
                            <b>Price total:</b> The total amount the host
                            received from the booking channel.
                          </li>
                          <li>
                            <b>Client Price:</b> The amount the guest paid on
                            the OTA platform, inclusive of channel fees (e.g.,
                            Airbnb, Booking.com).
                          </li>
                          <li>
                            <b>Client Profit:</b> Defined by the owner profit
                            formula that factors in the above variables.
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="isOptima">
          <v-text-field
            v-model="cleaningFee"
            class="mt-3"
            :label="$t('Cleaning Fee')"
            dense
            outlined
            hide-details
            @input="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="mb-4">
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-switch
                v-model="ownerCleaning"
                :label="$t('Display cleaning as owner income')"
                hide-details
                class="mb-3"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Owner cleaning</b> - cleaning would appear as income
                        on the owner p&l.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="align-baseline">
            <v-col cols="6">
              <date-picker
                v-model="cutoffDate"
                :hide-details="false"
                label="Cutoff date"
                :clearable="true"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Cutoff date</b> - Defines the starting point for
                        applying a new set of commission changes from a specific
                        date. This feature is useful for scenarios like
                        onboarding new owners to a listing or switching between
                        different calculation methods, such as moving from
                        check-in based to pro-rated commission models.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7">
          <v-expansion-panels class="mt-2">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="mt-1">Channel Settings</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-card-text>
                    <v-sheet
                      v-for="(val, key) in channels"
                      :key="key"
                      outlined
                      class="pa-2 rounded my-2"
                    >
                      <h5 class="text-h6 primary--text">
                        {{ val }}
                      </h5>
                      <v-switch
                        v-model="channel_settings[val].override"
                        label="Override pms settings"
                      />
                      <v-row no-gutters>
                        <v-col cols="auto">
                          <v-text-field
                            v-if="
                              channel_settings[val] &&
                              channel_settings[val].override
                            "
                            v-model="channel_settings[val].default_commission"
                            label="Commission"
                            dense
                            outlined
                            hide-details
                          />
                        </v-col>
                        <v-col cols="auto" class="ml-3">
                          <v-select
                            v-if="
                              channel_settings[val] &&
                              channel_settings[val].override
                            "
                            v-model="channel_settings[val].out_of"
                            label="Out of"
                            dense
                            outlined
                            hide-details
                            :items="[
                              { text: 'Total', value: 'total' },
                              {
                                text: 'Fare Accommodation',
                                value: 'fare_accommodation',
                              },
                            ]"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          v-if="
                            channel_settings[val] &&
                            channel_settings[val].override
                          "
                          cols="12"
                          class="d-flex"
                        >
                          <v-col cols="5" class="px-0">
                            <v-text-field
                              v-model.number="
                                channel_settings[val].processing_fee_override
                              "
                              outlined
                              dense
                              label="Owner Processing %"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                      <h5
                        v-if="
                          channel_settings[val] &&
                          channel_settings[val].override
                        "
                        class="v-label theme--light"
                      >
                        Merchant of record:
                      </h5>
                      <v-radio-group
                        v-if="
                          channel_settings[val] &&
                          channel_settings[val].override
                        "
                        v-model="channel_settings[val].processing_fee"
                        class="pb-2 ma-0"
                        hide-details
                        row
                      >
                        <v-radio label="Channel" :value="false" />
                        <v-radio label="PMC" :value="true" />
                      </v-radio-group> </v-sheet
                  ></v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import isEmpty from 'lodash/fp/isEmpty'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DatePicker from 'components/form-fields/date-picker'

import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
  OWNERS_INCOME_FORMULAS,
} from '@/consts'
import Common_functions from 'components/mixins/common_functions'

export default {
  name: 'ListingCommissions',
  props: ['listing'],
  components: { DatePicker },
  mixins: [PermissionsMixin, Common_functions],
  data() {
    return {
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
      OWNERS_INCOME_FORMULAS,
      channel_settings: this.listing.channel_settings,
      cutoffDate: this.listing.cutoff_date,
      netIncomeFormula: this.listing.net_income_formula,
      ownerRevenueFormula: this.listing.owner_revenue_formula,
      managementCommissionFormula: this.listing.management_commission_formula,
      managementCommissionAbove: this.listing.management_commission_above,
      ownerIncomeFormula: this.listing.owners_income_formula,
      cleaningFee: this.listing.actual_cleaning,
      ownerCleaning: this.listing.owner_cleaning,
      channels: [],
      disabledSave: true,
      dialog: false,
      commissionRecalcFrom: this.$moment()
        .utc()
        .startOf('month')
        .format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState(['settings']),
    zone() {
      return this.listing.zone || {}
    },
    managementCommissionAboveHint() {
      if (this.isHotel) {
        return "Commission applies to all hotel units. If empty, it's based on unit commission."
      } else if (this.isDisabledManagementCommissionAbove) {
        return `Management commission is set at ${this.parentManagementCommissionAbove}, inherited from parent settings. To change, adjust the parent commission.`
      } else {
        return null
      }
    },
    parentManagementCommissionAbove() {
      return this.listing.parent_management_commission_above
    },
    isDisabledManagementCommissionAbove() {
      return (
        this.isUnit &&
        this.parentManagementCommissionAbove !== null &&
        this.parentManagementCommissionAbove >= 0
      )
    },
    isHotel() {
      return this.listing.listing_type === 'hotel'
    },
    isUnit() {
      return !!this.listing.hotel_id
    },
  },
  async mounted() {
    await this.$store.dispatch('getSettings')
    this.channels = Object.keys(this.settings.channels || {})
    this.channel_settings = this.channels.reduce((acc, val) => {
      if (isEmpty(acc[val])) {
        acc[val] = { processing_fee: true, default_commission: 0 }
      }
      return acc
    }, this.channel_settings)
  },
  methods: {
    async updateListing() {
      await this.$store.dispatch('listings/updateListing', {
        id: this.$route.params.id,
        payload: {
          channel_settings: this.channel_settings,
          net_income_formula: this.netIncomeFormula,
          cutoff_date: this.cutoffDate,
          management_commission_formula: this.managementCommissionFormula,
          owner_revenue_formula: this.ownerRevenueFormula,
          management_commission_above: this.managementCommissionAbove,
          owners_income_formula: this.ownerIncomeFormula,
          owner_cleaning: this.ownerCleaning,
          actual_cleaning: this.cleaningFee,
          commission_recalc_from: this.commissionRecalcFrom,
        },
      })
      this.disabledSave = true
      this.dialog = false
    },
  },
  watch: {
    channel_settings: {
      deep: true,
      handler(val, old) {
        if (!isEmpty(old)) {
          this.disabledSave = false
        }
      },
    },
  },
}
</script>

<style scoped>
::v-deep .v-messages__message {
  color: darkblue;
  font-weight: 500;
}
::v-deep .v-select__selection {
  font-size: 14px;
}
.input-container {
  border: 1px var(--v-light400-darken3) solid;
  border-radius: 4px;
  padding: 10px;
}
</style>
